<template>
    <div class="page"  v-loading="loading">
        <div class="center-wrap">
            <div class="search-wrap">
                <el-input v-model="search"></el-input>
                <div class="search-bt" @click="sousuo">搜索</div>
            </div>
            <div class="center-title">搜索结果</div>
            <div class="wu" v-if="liebiaoList.length==0&&jieguo">没有符合条件的结果</div>
            <div class="center" v-if="liebiaoList.length>0">
                <div class="center-p">共{{total}}条搜索结果</div>
                <div class="center-item">
                    <div class="center-list" v-for="(item,index) in liebiaoList" :key="index">
                        <div class="center-list-left">
                            <div class="center-list-text">{{item.name}}</div>
                            <div class="center-list-p" v-html="delLabel(item.content)"></div>
                        </div>
                        <div class="center-list-bt" @click="goTo('search_details',{id:item.id})">查看详情</div>
                    </div>
                </div>

            </div>
            <div class="pages auto" v-if="liebiaoList.length>0">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page"
                    layout="prev, pager, next, jumper"
                    :page-size="pagination.nate"
                    :total="total"
                >
                </el-pagination>
            </div>

        </div>

    </div>
</template>

<script>
    import { getplatexts } from "../api/index"
    import {mapState, mapMutations, mapActions} from 'vuex' 
    export default {
        data() {
            return {
                pagination: {
                    page: 1,
                    nate: 10,
                },
                total:1,
                loading:false,
                search:'',
                jieguo:false,
                liebiaoList:[]
                
            }
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        methods: {
            handleCurrentChange(val) {
                // console.log(val);
                this.getplatexts();
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            sousuo(){
                if (this.search=='') {
                    return false
                }
                this.getplatexts()
                this.jieguo=true
            },
            // 跳转页面
            goTo(url,query) {
                if ( !this.isLogin) {                    
                    this.$confirm('请您先登录注册会员','温馨提示', {
                        confirmButtonText: '去登录',
                        }).then(() => {
                            this.$router.push({
                                path:'/login'
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '请登录后再操作'
                            });
                    });
                    return;
                }
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            getplatexts(){
                this.loading=true
                getplatexts({
                    ...this.pagination,
                    search:this.search,                    
                }).then((res)=>{
                    console.log(res)
                    setTimeout(() => {
                        this.loading=false
                    }, 500);
                    if (res.code==200) {
                        this.liebiaoList=res.data.data
                        this.total=res.data.total
                    }

                })
            }
            
        },
    }
</script>
<style lang="less" scoped>
.pages{
    margin: 50px auto;
    display: flex;
    justify-content: flex-end;
}
.center-wrap{
    width: 1200px;
    margin: auto;
    .search-wrap{
        width: 55%;
        margin: auto;
        display: flex;
        padding-top: 50px;
        .search-bt{
            width: 150px;
            background: #0071DB;
            color: #fff;
            font-size: 18px;
            text-align: center;
            line-height: 55px;
            flex-shrink: 0;
            cursor: pointer;
        }
        /deep/ .el-input__inner{height: 55px;border-radius:0;background: #EFEFEF;}
    }
    .wu{
        text-align: center;
        font-size: 18px;
        color: #666;
        padding-bottom: 40px;
        border-bottom: 1px solid #999;
    }
    .center-title{
        font-size: 30px;
        color: #222222;
        font-weight: bold;
        margin: 70px 0;
        text-align: center;
    }
    .center{
        .center-p{
            font-size: 16px;
            color: #7C7C7C;
            text-align: right;
        }
        .center-item{
            padding: 30px 0;
            .center-list{
                width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px;
                box-shadow: -2px 4px 18px 0px rgba(0,0,0,10%);
                cursor: pointer;
                margin-bottom: 35px;
                .center-list-left{
                    width: 70%;
                    .center-list-text{
                        color: #666666;
                        font-size: 22px;
                        margin-bottom: 20px;
                    }
                    .center-list-p{
                        color: #7C7C7C;
                        font-size: 16px;
                        line-height: 28px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
                .center-list-bt{
                    width: 140px;
                    height: 42px;
                    background: #BFBFBF;
                    border-radius: 21px;
                    line-height: 42px;
                    text-align: center;
                    font-size: 16px;color: #FEFEFE;
                }
                &:hover{
                    .center-list-left{
                        .center-list-text{
                            color: #004EC4;
                        }
                    }
                    .center-list-bt{
                        background: #0051A2;
                    }
                }
            }
        }

    }
}

</style>